/**
 * This is the initial loader for all js applications.
 */
(function()
{
	/** browser independent isArray()*/
	Array.isArray = Array.isArray ||
	function(obj)
	{
		return String(obj.constructor) == Array.toString();
	};

	/** browser independent trim()*/
	if ( typeof String.prototype.trim !== 'function')
	{
		String.prototype.trim = function()
		{
			return this.replace(/^\s+|\s+$/g, '');
		};
	}
	

	/** create the root name space*/
	var et = et ||
	{
	};

	/** get specified page parameter encoded as query string - with hash only*/
	
	et.getPageParameter = function(p_name)
	{
		var locHash = window.location.hash;
		if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
			locHash = "" + window.location;
			locHash = locHash.substr(locHash.indexOf('#'));
		}
		p_name = p_name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
		var regexS = "[\\?&]" + p_name + "=([^&#]*)";
		var regex = new RegExp(regexS);
		var results = regex.exec(locHash);
		if (results == null) {
			return "";
		}
		else {
			var ret = decodeURIComponent(results[1].replace(/\+/g, " "));
			return ret;
		}

	}; 

	
	
	et.getQueryParameter = function(name)
	{
		name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
		var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"), results = regex.exec(window.location);
		return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
	}; 


  /** returns debug value from the url parameter, either hash or search string */
  et.getDebug = function() {
	var isDebug = et.getPageParameter("debug"); // look for hash string
    if (!isDebug) {  // look for search query string
      var regex = new RegExp("[\\?&]debug=([^&#]*)");
      var results = regex.exec(window.location.search);
      if (results === null) {
        return "";
      }
      else {
        return decodeURIComponent(results[1].replace(/\+/g, " "));
      }
    }
    return isDebug;
  };

  et.debug = et.getDebug();

  /** for production DEBUG will set to false to disable the console logging*/
	if (!et.debug || !window.console)
	{
	window.console =
	{
	log : function()
	{
	},
	error : function()
	{
	},
	debug : function()
	{
	},
	warn : function()
	{		
	}
	};
	}

	/** constants */
	//TODO the version will be updated by CruiseControl
	et.version = "${VERSION}.${BUILD_NUMBER}";
	et.cookiePrefix = "et.";
	et.cookiePath = "/";

	/** modules definition*/
	var m_modules =
	{
		"3rd.jquery.core" : ["common7/lib/jquery/jquery-1.11.1.min.js"],
		"3rd.jquery.cookie" : ["common7/lib/jqueryplugin/jquery.cookie.js"],
		"3rd.bootstrap" : ["common7/lib/bootstrap/bootstrap.min.js"],
		"3rd.jquery.ui" : ["common7/lib/jqueryui/jquery-ui.js", "common7/lib/jqueryui/jquery-ui-i18n.min.js"],
		"3rd.jquery.jlayout" : ["common7/lib/jqueryplugin/jquery.sizes.js", "common7/lib/jqueryplugin/jlayout.border.js", "common7/lib/jqueryplugin/jquery.jlayout.js"],
		"3rd.jquery.jvalidation" : ["common7/lib/jqueryplugin/jquery.validate.min.js", "common7/lib/jqueryplugin/additional-methods.min.js", "common7/script/validation.methods.js"],
		"3rd.jquery.datepicker" : ["common7/lib/jqueryplugin/bootstrap-datepicker.js"],
		"3rd.jquery.typeahead" : ["common7/lib/jqueryplugin/typeahead.bundle.js"],
		"3rd.pwd" : ["common7/lib/jqueryplugin/jquery.password.strength.js"],
		"3rd.pqgrid" : ["common7/lib/pqgrid/pqgrid.min.js"],
		"3rd.dynatree" : ["common7/lib/dynatree/jquery.dynatree.min.js"],
		"3rd.jqGrid" : ["common7/lib/jqGrid/jquery.jqGrid.min.js", "common7/lib/jqGrid/grid.locale-en.js", "common7/lib/jqGrid/grid.common.js", "common7/lib/jqGrid/grid.formedit.js", "common7/lib/jqGrid/jqModal.js"],
		"3rd.codemirror" : ["common7/lib/codemirror/codemirror.js", "common7/lib/codemirror/mode/groovy/groovy.js", "common7/lib/codemirror/addon/edit/matchbrackets.js", "common7/lib/codemirror/addon/selection/active-line.js", "common7/lib/codemirror/util/formatting.js"],
		"3rd.ace" : ["common7/lib/ace/ace.js"],
        "3rd.google.jsapi" : ["https://www.google.com/jsapi"],
		"et.i18n" : ["common7/script/locale.js"],
		"et.i18n.widgets" : ["common7/script/locale_widgets.js"],
		"et.core" : ["common7/script/common.js"],
		"et.ui" : ["common7/script/widget.js"],
		"et.analytics" : ["common7/script/pa.js"],
		"et.binding" : ["common/lib/rivetsjs/rivets.min.js"],
		"et.sys.serverstatus" : ["sys/serverstatus/global/script/module.js", "sys/serverstatus/global/controller/workspace.js", "sys/serverstatus/global/presenter/shell.js", "sys/serverstatus/global/presenter/topmenu.js", "sys/serverstatus/monitor/presenter/monitor.js", "sys/serverstatus/monitor/presenter/inventory.js"],
		"et.sys.eventseatconv" : ["sys/eventseatconv/global/script/module.js", "sys/eventseatconv/global/controller/workspace.js", "sys/eventseatconv/global/presenter/shell.js", "sys/eventseatconv/global/presenter/topmenu.js", "sys/eventseatconv/conv/presenter/conv.js"],
		"et.sys.pdichannelmgmt" : ["sys/pdichannelmgmt/global/script/module.js", "sys/pdichannelmgmt/global/controller/workspace.js", "sys/pdichannelmgmt/global/presenter/shell.js", "sys/pdichannelmgmt/global/presenter/topmenu.js", "sys/pdichannelmgmt/pdi/presenter/pdi.js"],
		"et.sys.pdiscripteditor" : ["sys/pdiscripteditor/global/script/module.js", "sys/pdiscripteditor/global/controller/workspace.js", "sys/pdiscripteditor/global/presenter/shell.js", "sys/pdiscripteditor/global/presenter/topmenu.js", "sys/pdiscripteditor/pdi/presenter/pdi.js"],
		"et.sys.pdioutboundmonitor" : ["sys/pdioutboundmonitor/global/script/module.js", "sys/pdioutboundmonitor/global/controller/workspace.js", "sys/pdioutboundmonitor/global/presenter/shell.js", "sys/pdioutboundmonitor/global/presenter/topmenu.js", "sys/pdioutboundmonitor/pdi/presenter/pdi.js"],
		"et.sys.pdibatchmonitor" : ["sys/pdibatchmonitor/global/script/module.js", "sys/pdibatchmonitor/global/controller/workspace.js", "sys/pdibatchmonitor/global/presenter/shell.js", "sys/pdibatchmonitor/global/presenter/topmenu.js", "sys/pdibatchmonitor/pdi/presenter/pdi.js"],
		"et.sys.pdibatchmonitordetails" : ["sys/pdibatchmonitordetails/global/script/module.js", "sys/pdibatchmonitordetails/global/controller/workspace.js", "sys/pdibatchmonitordetails/global/presenter/shell.js", "sys/pdibatchmonitordetails/global/presenter/topmenu.js", "sys/pdibatchmonitordetails/pdi/presenter/pdi.js"],
		"et.sys.pdiglobalscripteditor" : ["sys/pdiglobalscripteditor/global/script/module.js", "sys/pdiglobalscripteditor/global/controller/workspace.js", "sys/pdiglobalscripteditor/global/presenter/shell.js", "sys/pdiglobalscripteditor/global/presenter/topmenu.js", "sys/pdiglobalscripteditor/pdi/presenter/pdi.js"],
		"et.sys.pdifullexport" : ["sys/pdimassload/global/script/module.js", "sys/pdimassload/global/controller/workspace.js", "sys/pdimassload/global/presenter/shell.js", "sys/pdimassload/global/presenter/topmenu.js", "sys/pdimassload/pdi/presenter/pdi_full.js"],
		"et.sys.pdipartialexport" : ["sys/pdimassload/global/script/module.js", "sys/pdimassload/global/controller/workspace.js", "sys/pdimassload/global/presenter/shell.js", "sys/pdimassload/global/presenter/topmenu.js", "sys/pdimassload/pdi/presenter/pdi.js"],
		"et.sys.pdirequestqueue" : ["sys/pdirequestqueue/global/script/module.js", "sys/pdirequestqueue/global/controller/workspace.js", "sys/pdirequestqueue/global/presenter/shell.js", "sys/pdirequestqueue/global/presenter/topmenu.js", "sys/pdirequestqueue/pdi/presenter/pdi.js"],
		"et.usi" : ["common7/script/usi.js"]
	};

	
	/** browser independent endsWith()*/
	String.prototype.endsWith = function(p_suffix)
	{
		return this.indexOf(p_suffix, this.length - p_suffix.length) !== -1;
	};

	/** load list of css files*/
	et.loadCSS = function(p_cssFiles)
	{
		if (!p_cssFiles)
			return;
		for (var i = 0; i < p_cssFiles.length; i++)
		{
			$("<link/>",
			{
				rel : "stylesheet",
				type : "text/css",
				href : p_cssFiles[i] + "?v=" + et.version
			}).appendTo("head");
		}

	};

	/** load scripts. if handler specified then load the scripts asynchronously, otherwise load synchronously*/
	et.loadScript = function(p_names, p_handler, p_errHandler)
	{
		if (p_handler)
		{
			et.loadScript.all = [];
			et.loadScript.load = 0;
			//calculate the number of files need to load
			for (var i = 0; i < p_names.length; i++)
			{
				if (Array.isArray(p_names[i]))
				{
					for (var j = 0; j < p_names[i].length; j++)
					{
						et.loadScript.all.push(et.getMinJs(p_names[i][j], true));
					}
				}
				else
				{
					var jsFiles = m_modules[p_names[i]];
					for (var j = 0; j < jsFiles.length; j++)
					{
						et.loadScript.all.push(et.getMinJs(jsFiles[j]));
					}
				}
			}

			//load all required js files
			m_loadJS(et.loadScript.all, 0, p_handler, p_errHandler);
		}
		else
		{
			var existFiles = document.getElementsByTagName('head')[0].childNodes;

			for (var i = 0; i < p_names.length; i++)
			{
				var jsFiles = m_modules[p_names[i]];
				for (var j = 0; j < jsFiles.length; j++)
				{
					var ignore = false;
					var jsFile = et.getMinJs(jsFiles[j]);

					for (var k = 0; k < existFiles.length; k++)
					{
						if (existFiles[k].src)
						{
							if (existFiles[k].src.endsWith(jsFile))
							{
								console.log(jsFile + " ignored.");
								ignore = true;
								break;
							}
						}

					}
					if (!ignore)
					{
						m_preLoadJS(jsFile);
						console.log(jsFile + " loaded synchronous.");
					}
				}
			}
		}
	};

	/** create name space*/
	et.provide = function(p_name)
	{
		var s = p_name.split('.');
		var parent = window;
		for (var i = 0; i < s.length; i++)
		{
			parent = m_createNS(parent, s[i]);
		}
		return parent;
	};

	/** load the module with init container*/
	et.loadModule = function(p_module, p_container)
	{
		p_module.onLoad(p_container);
	};

	/** get the minified version of the javscript file*/
	et.getMinJs = function(p_file, rel) {
		//disable js minify for the time being, except for customize codes.
		if ((false && !et.debug && !/.*common7\/lib\/.*/.test(p_file) && !/^http.*/.test(p_file) && !/.*\.min\.js($|\?.*)/.test(p_file)) || (!et.debug && /.*customize\/.*/.test(p_file) && !/.*\.min\.js($|\?.*)/.test(p_file))) {
			var file = p_file.replace(/\.js/, '.min.js');
			if (rel === true) {
				file = new String(file);
				file.rel = rel;
			}
			return file;
		}
		if (rel === true) {
			p_file = new String(p_file);
			p_file.rel = rel;
		}
		return p_file;
	};
	
	/** get all page parameters encoded as query string*/
	
	et.getPageParameters = function()
	{
		var locHash = window.location.hash;
		if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
			locHash = "" + window.location;
			locHash = locHash.substring(locHash.indexOf('#'));
		}

		var inx = locHash.indexOf("?");
		if (inx > -1) {
			var args = locHash.substr(inx + 1).split('&');

			var argsParsed = {
			};

			for ( i = 0; i < args.length; i++) {
				arg = args[i];

				if (arg.indexOf('=') == -1) {
					argsParsed[arg.trim()] = true;
				}
				else {
					kvp = arg.split('=');
					argsParsed[kvp[0].trim()] = decodeURIComponent(kvp[1].trim());
				}
			}
			return argsParsed;
		}
	}; 


	/*************************************************/

	/**internal function to pre-load javascript files*/
	var m_preLoadJS = function(p_jsFile)
	{
		//pre-load
		var xhrObj = m_createXMLHTTPObject();
		if (!et.rootContext)
		{
			et.rootContext = "~{url.prefix}";
		}
		xhrObj.open('GET', p_jsFile.rel ? p_jsFile + "?v=" + et.version : et.rootContext + p_jsFile + "?v=" + et.version, false);
		xhrObj.send('');
		var se = document.createElement('script');
		se.type = "text/javascript";
		se.text = xhrObj.responseText;
		document.getElementsByTagName('head')[0].appendChild(se);
	};

	/**internal function to load javascript files*/
	var m_loadJS = function(p_jsFiles, p_index, p_handler, p_errHandler)
	{
		if (p_jsFiles.length == p_index)
			return;

		var js = document.createElement('script');
		js.type = "text/javascript";
		if (!et.rootContext)
		{
			et.rootContext = "~{url.prefix}";
		}
		var rawPath = p_jsFiles[p_index];
		if (rawPath.match(/^(http|https)/))
		{
			// external js file.
			js.src = rawPath;
		}
		else
		{
			js.src = rawPath.rel ? rawPath + "?v=" + et.version : et.rootContext + rawPath + "?v=" + et.version;
		}
		if (js.readyState)
		{
			// IE
			js.onreadystatechange = function()
			{
				return function()
				{
					if (this.readyState == "loaded" || this.readyState == "complete")
					{
						this.onreadystatechange = null;

						console.log(this.src + " loaded.");
						if (p_jsFiles.length == (p_index + 1))
						{
							//loaded all js files.
							p_handler.call();
						}
						else
						{
							m_loadJS(p_jsFiles, ++p_index, p_handler);
						}
					}
				};
			}();

			js.onerror = function()
			{
				console.log(this.src + " failed to load.");
				this.onreadystatechange = null;
				if (p_errHandler)
				{
					p_errHandler.call();
				}
				else
				{
					p_handler.call();
				}
			};
		}
		else
		{
			// Others
			js.onload = function()
			{
				return function()
				{
					console.log(this.src + " loaded.");
					if (p_jsFiles.length == (p_index + 1))
					{
						//loaded all js files.
						p_handler.call();
					}
					else
					{
						m_loadJS(p_jsFiles, ++p_index, p_handler);
					}
				};
			}();

			js.onerror = function()
			{
				console.log(this.src + " failed to load.");
				this.onreadystatechange = null;
				if (p_errHandler)
				{
					p_errHandler.call();
				}
				else
				{
					p_handler.call();
				}

			};
		}
		document.getElementsByTagName("head")[0].appendChild(js);
	};

	/** internal function to create name space*/
	var m_createNS = function(p_parent, p_childName)
	{
		p_parent[p_childName] = p_parent[p_childName] ||
		{
		};
		return p_parent[p_childName];
	};

	var m_XMLHttpFactories = [
	function()
	{
		return new XMLHttpRequest();
	},
	function()
	{
		return new ActiveXObject("Msxml2.XMLHTTP");
	},
	function()
	{
		return new ActiveXObject("Msxml3.XMLHTTP");
	},
	function()
	{
		return new ActiveXObject("Microsoft.XMLHTTP");
	}];
	var m_createXMLHTTPObject = function()
	{
		var xmlhttp = false;
		for (var i = 0; i < m_XMLHttpFactories.length; i++)
		{
			try
			{
				xmlhttp = m_XMLHttpFactories[i]();
			}
			catch (e)
			{
				continue;
			}
			break;
		}
		return xmlhttp;
	};

	/** expose et */
	window.et = et;

})();
